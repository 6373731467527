@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?5swqva");
  src: url("fonts/icomoon.eot?5swqva#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?5swqva") format("truetype"), url("fonts/icomoon.woff?5swqva") format("woff"), url("fonts/icomoon.svg?5swqva#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-contact-small-mail:before {
  content: "\e911"; }

.icon-contact-small-phone:before {
  content: "\e912"; }

.icon-weight:before {
  content: "\e900"; }

.icon-crane:before {
  content: "\e901"; }

.icon-crane-1:before {
  content: "\e902"; }

.icon-weight-1:before {
  content: "\e903"; }

.icon-house-roof:before {
  content: "\e904"; }

.icon-warehouse:before {
  content: "\e905"; }

.icon-warehouse-1:before {
  content: "\e906"; }

.icon-stock:before {
  content: "\e907"; }

.icon-house:before {
  content: "\e908"; }

.icon-road:before {
  content: "\e909"; }

.icon-bridge:before {
  content: "\e90a"; }

.icon-sydney:before {
  content: "\e90b"; }

.icon-road-1:before {
  content: "\e90c"; }

.icon-swimming-pool:before {
  content: "\e90d"; }

.icon-swimming-pool-1:before {
  content: "\e90e"; }

.icon-swimming-pool-2:before {
  content: "\e90f"; }

.icon-car-and-cliff:before {
  content: "\e910"; }

#app {
  overflow: hidden; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 162rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -4.5rem;
      margin-right: -4.5rem; } }

.pux-container [class^="col"] {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container [class^="col"] {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

.small-container {
  max-width: 100rem;
  margin: auto; }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 888;
  background: #fff;
  border-bottom: 1px solid transparent;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    header .header-logo a {
      display: inline-block; }
      header .header-logo a img {
        height: 5rem !important; }
    header .header-logo-title {
      display: block;
      font-weight: 500;
      color: #000;
      font-size: 4.4rem; }
      @media (min-width: 480px) {
        header .header-logo-title {
          font-size: 4rem; } }
      @media (min-width: 545px) {
        header .header-logo-title {
          font-size: 3.66667rem; } }
      @media (min-width: 768px) {
        header .header-logo-title {
          font-size: 2.93333rem; } }
      @media (min-width: 992px) {
        header .header-logo-title {
          font-size: 2.75rem; } }
      @media (min-width: 1200px) {
        header .header-logo-title {
          font-size: 2.44444rem; } }
      @media (min-width: 1400px) {
        header .header-logo-title {
          font-size: 2.2rem; } }
    header .header-logo-signature {
      display: block;
      text-align: left;
      color: #cecece;
      font-size: 2.8rem; }
      @media (min-width: 480px) {
        header .header-logo-signature {
          font-size: 2.54545rem; } }
      @media (min-width: 545px) {
        header .header-logo-signature {
          font-size: 2.33333rem; } }
      @media (min-width: 768px) {
        header .header-logo-signature {
          font-size: 1.86667rem; } }
      @media (min-width: 992px) {
        header .header-logo-signature {
          font-size: 1.75rem; } }
      @media (min-width: 1200px) {
        header .header-logo-signature {
          font-size: 1.55556rem; } }
      @media (min-width: 1400px) {
        header .header-logo-signature {
          font-size: 1.4rem; } }
    header .header-menu {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 77;
      left: 0;
      background: #fff;
      width: 100%;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem);
      border-top: 1px solid #e9ecef;
      border-bottom: 1px solid #e9ecef; }
      @media (min-width: 768px) {
        header .header-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: auto;
          border: none; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        @media (min-width: 768px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; } }
        header .header-menu ul li {
          display: block;
          position: relative; }
          @media (min-width: 768px) {
            header .header-menu ul li {
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; } }
          @media (min-width: 992px) {
            header .header-menu ul li {
              margin: 0 1rem; } }
          header .header-menu ul li a {
            display: block;
            padding: 2.5rem 1.5rem;
            color: #000;
            text-transform: uppercase;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            text-align: center; }
            header .header-menu ul li a:focus, header .header-menu ul li a:hover {
              color: #3883c4;
              text-decoration: none; }
          header .header-menu ul li.Highlighted a {
            color: #3883c4; }
          header .header-menu ul li ul {
            background: #fff;
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 768px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 20rem;
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                top: 100%; } }
          header .header-menu ul li.hovered > a, header .header-menu ul li:hover > a {
            color: #3883c4; }
          header .header-menu ul li.hovered > ul, header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: #3883c4;
        color: #fff;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 768px) {
          header .header-menu .menu-openner {
            display: none; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: "";
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      header .header-hamburger {
        display: none; } }
    header .header-hamburger .open-menu {
      width: 6rem;
      height: 6rem;
      position: relative;
      display: block; }
      header .header-hamburger .open-menu span {
        width: 5rem;
        position: absolute;
        left: 0.5rem;
        height: 2px;
        background: #3883c4;
        margin-top: -1px;
        top: 50%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
          content: "";
          width: 5rem;
          left: 0;
          height: 2px;
          background: #3883c4;
          position: absolute;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before {
          top: -1rem; }
        header .header-hamburger .open-menu span:after {
          bottom: -1rem; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      display: block; }
  header.scrolled {
    border-bottom: 1px solid #cecece; }
    header.scrolled .header-menu ul li a {
      padding: 1.5rem 1.5rem; }
  header .border {
    border-bottom: 1px solid #cecece; }

.EditMode header,
.DesignMode header {
  position: static; }

.header-helper {
  width: 100%;
  height: 54px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 768px) {
    .header-helper {
      height: 7.4rem; } }
  .header-helper.scrolled {
    height: 5.5rem; }

footer {
  padding: 5rem 0 0 0;
  background: #16334d;
  color: #fff; }
  footer .footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
    line-height: 28px; }
    footer .footer-item {
      padding: 0 1.5rem 3rem 1.5rem;
      width: 100%;
      text-align: center; }
      @media (min-width: 480px) {
        footer .footer-item {
          width: auto;
          text-align: left; } }
  footer .copyright {
    text-align: center;
    font-size: 2.8rem;
    background: #16334d;
    padding: 1rem 0 3rem 0;
    color: #aba9a9; }
    @media (min-width: 480px) {
      footer .copyright {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      footer .copyright {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      footer .copyright {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      footer .copyright {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      footer .copyright {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      footer .copyright {
        font-size: 1.4rem; } }
    @media (min-width: 480px) {
      footer .copyright {
        text-align: left; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

strong,
.bold {
  font-weight: 500; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
  color: #000; }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.6rem; } }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  margin: 0;
  padding: 5rem 0 4rem 0;
  color: #3883c4; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 6rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 5.45455rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      font-size: 5rem; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 4.5rem; } }

h2,
.h2 {
  margin: 0;
  padding: 3rem 0 3rem 0;
  color: #3883c4; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 5.6rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 5.09091rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 4.66667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 3.8rem; } }

h3,
.h3 {
  color: #3883c4;
  padding: 3rem 0 3rem 0;
  margin: 0; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 3rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    font-size: 3rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 2.5rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2.2rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 4.4rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    line-height: 4rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    line-height: 3.66667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 2.93333rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    line-height: 2.75rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 2.6rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 3rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.4rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 2.25rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 1.8rem; } }

a {
  color: #3883c4;
  cursor: pointer; }
  a:focus, a:hover {
    color: #2d689c;
    outline: none;
    text-decoration: none; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 1.5rem 3rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem; }
  @media (min-width: 320px) {
    .btn {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 1.8rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary {
  background: #3883c4;
  color: #fff; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #16334d;
    color: #fff; }

.btn-default {
  background: #16334d;
  color: #000; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #102639;
    color: #000; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #3883c4;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: "Roboto", sans-serif !important; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 2rem 0;
      padding: 0 0 0 3.5rem;
      position: relative; }
      @media (min-width: 768px) {
        main ul li {
          padding: 0 0 0 2.5rem;
          margin: 0 0 1.5rem 0; } }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #3883c4;
        position: absolute;
        top: 8px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 1rem 1.5rem; }
        main table tr td:first-child {
          font-weight: 500;
          vertical-align: top;
          text-transform: uppercase; }
        main table tr td:last-child {
          text-align: right;
          color: #818281; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #e8e8e8; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Roboto", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    .label-form,
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .label-form,
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .label-form,
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .label-form,
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .label-form,
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .label-form,
    label {
      font-size: 1.4rem; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #f1f1f1;
  background: #f1f1f1;
  color: #000;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  @media (min-width: 320px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #000; }
  .form-control:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  textarea.form-control:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #000; }
  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  textarea.form-control::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #000; }
  .form-control:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #000; }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #3883c4; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }
  textarea.form-control:focus,
  textarea:focus {
    border: 1px solid #3883c4; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #3883c4;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #3883c4;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #16334d;
  color: #000; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #3883c4;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:before {
    border: 1px solid #3883c4; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #3883c4;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #3883c4; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.product-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .product-container .product-box {
    width: 100%;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff; }
    @media (min-width: 545px) {
      .product-container .product-box {
        width: 50%; } }
    @media (min-width: 768px) {
      .product-container .product-box {
        width: 33.33%; } }

.product-item {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden; }
  .product-item:after {
    content: "";
    display: block;
    padding-bottom: 60%; }
    @media (min-width: 545px) {
      .product-item:after {
        padding-bottom: 100%; } }
  .product-item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: bottom center !important;
    -moz-transition: all ease-in-out 400ms;
    -o-transition: all ease-in-out 400ms;
    -webkit-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms; }
    .product-item-image:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      position: absolute; }
  .product-item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    @media (min-width: 1200px) {
      .product-item-content {
        padding: 5rem; } }
    .product-item-content-name {
      font-weight: 500;
      color: #fff;
      text-transform: uppercase; }
      @media (min-width: 320px) {
        .product-item-content-name {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .product-item-content-name {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .product-item-content-name {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .product-item-content-name {
          font-size: 3rem; } }
    .product-item-content-description {
      -webkit-align-self: flex-end;
      -moz-align-self: flex-end;
      -ms-align-self: flex-end;
      -webkit-align-self: flex-end;
      align-self: flex-end;
      color: #fff; }
      @media (min-width: 320px) {
        .product-item-content-description {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .product-item-content-description {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .product-item-content-description {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .product-item-content-description {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .product-item-content-description {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .product-item-content-description {
          font-size: 1.8rem; } }
  .product-item:focus .product-item-image, .product-item:hover .product-item-image {
    -ms-transform: scale(1.05, 1.05);
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05); }

.product-box:last-child {
  width: 100%; }
  @media (min-width: 545px) {
    .product-box:last-child {
      width: 100%;
      border-right: none; }
      .product-box:last-child .product-item:after {
        content: "";
        display: block;
        padding-bottom: 60%; } }
  @media (min-width: 768px) {
    .product-box:last-child {
      width: 33.33%; }
      .product-box:last-child .product-item:after {
        content: "";
        display: block;
        padding-bottom: 100%; } }

.grey-box {
  background: #fff; }

.gallery-container {
  border-bottom: 1px solid #cecece; }
  .gallery-container-btn {
    width: 100%; }
  .gallery-container-more {
    display: none; }
  .gallery-container.open .gallery-container-btn {
    display: none; }
  .gallery-container.open .gallery-container-more {
    display: block; }

.gallery-item {
  display: block; }
  .gallery-item img {
    height: 24rem !important;
    width: auto; }
    @media (min-width: 768px) {
      .gallery-item img {
        height: 29rem !important; } }

.offer-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 -2rem; }
  .offer-container .offer-box {
    width: 50%;
    padding: 0 2rem 5rem 2rem; }
    @media (min-width: 545px) {
      .offer-container .offer-box {
        width: 50%; } }
    @media (min-width: 992px) {
      .offer-container .offer-box {
        width: 33.33%; } }
    @media (min-width: 1200px) {
      .offer-container .offer-box {
        width: 25%; } }

.offer-item {
  text-align: center; }
  @media (min-width: 545px) {
    .offer-item {
      padding: 0 2rem; } }
  .offer-item-ico:before {
    font-size: 7rem;
    color: #16334d; }
  .offer-item-ico.ico-1:before {
    content: "\e900"; }
  .offer-item-ico.ico-2:before {
    content: "\e901"; }
  .offer-item-ico.ico-3:before {
    content: "\e904"; }
  .offer-item-ico.ico-4:before {
    content: "\e907"; }
  .offer-item-ico.ico-5:before {
    content: "\e908"; }
  .offer-item-ico.ico-6:before {
    content: "\e909"; }
  .offer-item-ico.ico-7:before {
    content: "\e90e"; }
  .offer-item-ico.ico-8:before {
    content: "\e910"; }
  .offer-item-content {
    padding: 3rem 0 0 0; }
    @media (min-width: 320px) {
      .offer-item-content {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .offer-item-content {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .offer-item-content {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .offer-item-content {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .offer-item-content {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .offer-item-content {
        font-size: 1.8rem; } }

.contact-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  padding: 5rem 0 0 0; }
  .contact-container .contact-box {
    width: 100%;
    padding: 0 1.5rem 3rem 1.5rem; }
    @media (min-width: 545px) {
      .contact-container .contact-box {
        width: 50%; } }

.contact-item {
  color: #000;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #fff; }
  @media (min-width: 320px) {
    .contact-item {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .contact-item {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .contact-item {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .contact-item {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .contact-item {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .contact-item {
      font-size: 2.2rem; } }
  .contact-item:before {
    font-size: 4rem;
    margin: 0 2rem 0 0;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    color: #050c12; }
  .contact-item.contact-email:before {
    content: "\e911"; }
  .contact-item.contact-phone:before {
    content: "\e912"; }
  .contact-item:hover, .contact-item:focus {
    color: #050c12; }

.product-detail {
  background: #fff;
  position: relative;
  border-bottom: 1px solid #cecece; }
  .product-detail-flex {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 60rem; }
    .product-detail-flex-left {
      width: 100%;
      padding: 3rem 0 3rem 0; }
      @media (min-width: 992px) {
        .product-detail-flex-left {
          width: 50%;
          padding: 3rem 6rem 3rem 0; } }
    .product-detail-flex-right {
      width: 100%;
      padding: 0 0 4rem 0;
      position: relative;
      min-height: 50rem; }
      @media (min-width: 992px) {
        .product-detail-flex-right {
          width: 50%;
          padding: 0;
          position: static; } }
  .product-detail-image {
    width: 100%;
    height: 100%; }
    @media (min-width: 992px) {
      .product-detail-image {
        width: 50%;
        position: absolute;
        top: 0;
        right: 0; } }
    .product-detail-image img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      font-family: 'object-fit: cover'; }
  .product-detail-description {
    padding: 2rem 0 4rem 0; }

.product-detail-sidebar img {
  max-width: 100%; }

.contact-box {
  background: #3883c4;
  color: #fff; }
  .contact-box h2 {
    color: #fff; }

.ReactGridGallery {
  height: 290px;
  overflow: hidden; }

.open .ReactGridGallery {
  height: auto; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  background: #f1f1f1;
  position: relative;
  overflow: hidden;
  border: 2px solid #f1f1f1;
  font-weight: 900;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .selectric .button {
        width: 5.45455rem; } }
    @media (min-width: 545px) {
      .selectric .button {
        width: 5rem; } }
    @media (min-width: 768px) {
      .selectric .button {
        width: 4rem; } }
    @media (min-width: 992px) {
      .selectric .button {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .selectric .button {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .selectric .button {
        width: 3rem; } }
    .selectric .button:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-top: 1.5rem solid #3883c4; }
      @media (min-width: 768px) {
        .selectric .button:before {
          width: 0;
          height: 0;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-top: 1rem solid #3883c4; } }

.selectric-focus .selectric {
  border-color: #3883c4; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #3883c4;
    border-bottom: 1px solid #3883c4; }
    .selectric-open .selectric .button {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #3883c4;
    border-top: 1px solid #3883c4; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #d8d8d8;
  border: 2px solid #f1f1f1;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    color: #000;
    background: #e4e4e4;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #000; }
    .selectric-items li.highlighted {
      color: #000; }
    .selectric-items li:hover {
      background: #dfdfdf; }
    .selectric-items li:nth-child(odd) {
      background: #d8d8d8; }
      .selectric-items li:nth-child(odd):hover {
        background: #d2d2d2; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    color: #000 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none;
    color: #000; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    background: #bebebe;
    vertical-align: middle;
    position: static;
    border: 2px solid #bebebe; }
  .list-box .selectric-scroll ul li.selected:before {
    background: #3883c4; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

@media print {
  header,
  footer {
    display: none; } }
