
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

#app{
  overflow: hidden;
}

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    [class^="col"]{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

.small-container{
  max-width: 100rem;
  margin:auto;
}

header{
  position: fixed;
  width: 100%;
  top:0;
  left:0;
  z-index: 888;
  background: #fff;
  border-bottom:1px solid transparent;
  @include transition;
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    &-logo{
      a{
        display:inline-block;
        img{
          height: 5rem !important;
        }
      }
      &-title{
        display: block;
        font-weight: 500;
        color:#000;
        @include pux-static-size("font-size", 22px);
      }
      &-signature{
        display: block;
        text-align: left;
        color:$base-grey;
        @include pux-static-size("font-size", 14px);
      }
    }
    &-menu{
      display:none;
      position: absolute;
      top:100%;
      z-index: 77;
      left:0;
      background: #fff;
      width: 100%;
      @include calc("max-height", "100vh - 5rem");
      border-top:1px solid #e9ecef;
      border-bottom:1px solid #e9ecef;
      @include media($menu-break){
        @include flex-block;
        position: static;
        background: none;
        width:auto;
        border:none;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include media($menu-break){
          @include flex-block;
        }
        li{
          display: block;
          position: relative;
          @include media($menu-break){
            @include flex-block;
            @include align-items(center);
          }
          @include media(lg){
            margin:0 1rem;
          }
          a{
            display:block;
            padding:2.5rem 1.5rem;
            color:$base-black;
            text-transform: uppercase;
            @include transition;
            text-align: center;
            &:focus,
            &:hover{
              color:$main-color;
              text-decoration: none;
            }
          }
          &.Highlighted{
            a{
              color:$main-color;
            }
          }
          ul{
            background: #fff;
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              width:20rem;
              visibility: hidden;
              @include opacity(0);
              display: block;
              top:100%;
            }
            li{
              a{
                &:focus,
                &:hover{

                }
              }
            }
          }
          &.hovered,
          &:hover{
            & > a{
              color:$main-color;
            }
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right:0;
        background: $main-color;
        color:#fff;
        width: 6rem;
        height: 6rem;
        @include media($menu-break){
          display: none;
        }
        &:after,
        &:before{
          content:"";
          width:70%;
          height:2px;
          background:#fff;
          position: absolute;
          left:15%;
          top:50%;
          margin-top:-1px;
        }
        &:after{
          @include rotate(90deg);
          @include transition;
        }
      }
    }
    &-hamburger{
      @include media($menu-break){
        display: none;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        span{
          width:$hamburger-width - 1rem;
          position: absolute;
          left:0.5rem;
          height:2px;
          background: $main-color;
          margin-top:-1px;
          top:50%;
          @include transition;
          &:before,
          &:after{
            content:"";
            width:$hamburger-width - 1rem;
            left:0;
            height:2px;
            background: $main-color;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-1rem;
          }
          &:after{
            bottom:-1rem;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        display: block;
      }
    }
  }
  &.scrolled{
    border-bottom:1px solid $base-grey;
    .header-menu ul li a{
      padding:1.5rem 1.5rem;
    }
  }
  .border{
    border-bottom:1px solid $base-grey;
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

.header-helper{
  width: 100%;
  height: 54px;
  @include transition;
  @include media($menu-break){
    height: 7.4rem;
  }
  &.scrolled{
    height: 5.5rem;
  }
}

footer{
  padding: 5rem 0 0 0;
  background: $second-color;
  color:#fff;
  .footer{
    @include flex-block;
    @include justify-content(space-between);
    @include align-items(center);
    @include flex-wrap(wrap);
    margin:0 -1.5rem;
    line-height: 28px;
    &-item{
      padding:0 1.5rem 3rem 1.5rem;
      width:100%;
      text-align: center;
      @include media(xs){
        width: auto;
        text-align: left;
      }
    }
  }
  .copyright{
    text-align: center;
    @include pux-static-size("font-size", 14px);
    background: $second-color;
    padding:1rem 0 3rem 0;
    color:#aba9a9;
    @include media(xs){
      text-align: left;
    }
  }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}
