@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5swqva');
  src:  url('fonts/icomoon.eot?5swqva#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5swqva') format('truetype'),
    url('fonts/icomoon.woff?5swqva') format('woff'),
    url('fonts/icomoon.svg?5swqva#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-contact-small-mail:before {
  content: "\e911";
}
.icon-contact-small-phone:before {
  content: "\e912";
}
.icon-weight:before {
  content: "\e900";
}
.icon-crane:before {
  content: "\e901";
}
.icon-crane-1:before {
  content: "\e902";
}
.icon-weight-1:before {
  content: "\e903";
}
.icon-house-roof:before {
  content: "\e904";
}
.icon-warehouse:before {
  content: "\e905";
}
.icon-warehouse-1:before {
  content: "\e906";
}
.icon-stock:before {
  content: "\e907";
}
.icon-house:before {
  content: "\e908";
}
.icon-road:before {
  content: "\e909";
}
.icon-bridge:before {
  content: "\e90a";
}
.icon-sydney:before {
  content: "\e90b";
}
.icon-road-1:before {
  content: "\e90c";
}
.icon-swimming-pool:before {
  content: "\e90d";
}
.icon-swimming-pool-1:before {
  content: "\e90e";
}
.icon-swimming-pool-2:before {
  content: "\e90f";
}
.icon-car-and-cliff:before {
  content: "\e910";
}
