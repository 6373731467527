
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.product-container{
  @include flex-block;
  @include flex-wrap(wrap);
  .product-box{
    width: 100%;
    border-right:2px solid #fff;
    border-bottom:2px solid #fff;
    @include media(sm){
      width:50%;
    }
    @include media(md){
      width:33.33%;
    }
  }
}

.product-item{
  width:100%;
  display: block;
  position: relative;
  overflow: hidden;
  &:after{
    content: "";
    display: block;
    padding-bottom: 60%;
    @include media(sm){
      padding-bottom: 100%;
    }
  }
  &-image{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    background-size: cover !important;
    background-position: bottom center !important;
    @include transition(400ms);
    &:before{
      content: "";
      width: 100%;
      height:100%;
      background: rgba(0,0,0,0.4);
      position: absolute;
    }
  }
  &-content{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    padding:3rem;
    @include flex-block;
    @include flex-wrap(wrap);
    @include media(xl){
      padding:5rem;
    }
    &-name{
      font-weight: 500;
      color:#fff;
      text-transform: uppercase;
      @include pux-scale-with-min("font-size", 30px, 20px);
    }
    &-description{
      @include align-self(flex-end);
      color:#fff;
      @include pux-scale-with-min("font-size", 18px, 16px);
    }
  }
  &:focus,
  &:hover{
    .product-item-image{
      @include scale(1.05, 1.05);
    }
  }
}

.product-box{
  &:last-child{
    width: 100%;
    @include media(sm){
      width:100%;
      border-right:none;
      .product-item{
        &:after{
          content: "";
          display: block;
          padding-bottom: 60%;
        }
      }
    }
    @include media(md){
      width:33.33%;
      .product-item{
        &:after{
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
  }
}

.grey-box{
  background: #fff;
}

.gallery-container{
  border-bottom: 1px solid #cecece;
  &-btn{
    width: 100%;
  }
  &-more{
    display: none;
  }
  &.open{
    .gallery-container-btn{
      display: none;
    }
    .gallery-container-more{
      display: block;
    }
  }
}

.gallery-item{
  display: block;
  img{
    height:24rem !important;
    width: auto;
    @include media(md){
      height:29rem !important;
    }
  }
}

.offer-container{
  @include flex-block;
  @include flex-wrap(wrap);
  @include justify-content(center);
  margin:0 -2rem;
  .offer-box{
    width:50%;
    padding:0 2rem 5rem 2rem;
    @include media(sm){
      width: 50%;
    }
    @include media(lg){
      width:33.33%;
    }
    @include media(xl){
      width: 25%;
    }
  }
}

.offer-item{
  text-align: center;
  @include media(sm){
    padding:0 2rem;
  }
  &-ico{
    &:before{
      font-size: 7rem;
      color:$second-color;
    }
    &.ico-1{
      &:before{
        content:"\e900";
      }
    }
    &.ico-2{
      &:before{
        content:"\e901";
      }
    }
    &.ico-3{
      &:before{
        content:"\e904";
      }
    }
    &.ico-4{
      &:before{
        content:"\e907";
      }
    }
    &.ico-5{
      &:before{
        content:"\e908";
      }
    }
    &.ico-6{
      &:before{
        content:"\e909";
      }
    }
    &.ico-7{
      &:before{
        content:"\e90e";
      }
    }
    &.ico-8{
      &:before{
        content:"\e910";
      }
    }
  }
  &-content{
    padding:3rem 0 0 0;
    @include pux-scale-with-min("font-size", 18px, 16px);
  }
}

.contact-container{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -1.5rem;
  padding:5rem 0 0 0;
  .contact-box{
    width:100%;
    padding:0 1.5rem 3rem 1.5rem;
    @include media(sm){
      width: 50%;
    }
  }
}

.contact-item{
  @include pux-scale-with-min("font-size", 22px, 18px);
  color:#000;
  @include flex-inline;
  @include align-items(center);
  color:#fff;
  &:before{
    font-size: 4rem;
    margin:0 2rem 0 0;
    @include transition;
    color: darken($second-color, 15);
  }
  &.contact-email{
    &:before{
      content:"\e911";
    }
  }
  &.contact-phone{
    &:before{
      content:"\e912";
    }
  }
  &:hover,
  &:focus{
    color:darken($second-color, 15);
  }
}

.product-detail{
  background: #fff;
  position: relative;
  border-bottom: 1px solid $base-grey;
  &-flex{
    @include flex-block;
    @include flex-wrap(wrap);
    min-height: 60rem;
    &-left{
      width:100%;
      padding:3rem 0 3rem 0;
      @include media(lg){
        width: 50%;
        padding:3rem 6rem 3rem 0;
      }
    }
    &-right{
      width:100%;
      padding:0 0 4rem 0;
      position: relative;
      min-height: 50rem;
      @include media(lg){
        width: 50%;
        padding:0;
        position: static;
      }
    }
  }
  &-image{
    width: 100%;
    height:100%;
    @include media(lg){
      width: 50%;
      position: absolute;
      top:0;
      right:0;
    }
    img{
      width: 100%;
      height:100% !important;
      object-fit: cover;
      font-family: 'object-fit: cover';
    }
  }
  &-description{
    padding:2rem 0 4rem 0;
  }
}

.product-detail-sidebar{
  img{
    max-width: 100%;
  }
}

.contact-box{
  background: $main-color;
  color:#fff;
  h2{
    color:#fff;
  }
}

.ReactGridGallery{
  height: 290px;
  overflow: hidden;
}

.open{
  .ReactGridGallery{
    height: auto;
  }
}
